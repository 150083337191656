import React from "react";

import styles from "./Spinner.module.scss";

const Spinner = ({ children }) => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinnerCircle} />
      <div>{children}</div>
    </div>
  );
};
export default Spinner;
