import React, { useContext } from "react";

import ReportContext from "../store/ReportContext";
import _ from "lodash";

import styles from "./ReceiptUpdateTable.module.scss";

const ReceiptUpdateTable = () => {
  const { isUpdated } = useContext(ReportContext);

  // Calculate total amount from uploaded receipt
  const calculateAmount = isUpdated.map((amount) => {
    const displayAmount = amount.amount_cents;
    return displayAmount;
  });

  return (
    <div className={styles.tableContainer}>
      <table className={styles.scrollable}>
        <caption className={styles.summaryHeader}>Receipts Summary</caption>
        <thead className={styles.infoTableHead}>
          <tr className={styles.infoRow}>
            <th className={styles.tableHead}>SELLER</th>
            <th className={styles.tableHead}>DESCRIPTION</th>
            <th className={styles.tableHead}>AMOUNT</th>
            <th className={styles.tableHead}>EU</th>
          </tr>
        </thead>
        <tbody className={styles.infoTableContent}>
          {isUpdated.map((receipt) => {
            return (
              <tr key={receipt.id} className={styles.infoContentRow}>
                <td className={styles.tableData}>{receipt.seller}</td>
                <td className={styles.tableData}>{receipt.description}</td>
                <td className={styles.tableData}>
                  <span>€</span> {receipt.amount_cents / 100}
                </td>
                <td className={styles.tableData}>
                  {receipt.european_union === true ? "X" : "-"}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className={styles.tableFoot}>
					<tr>
						<td className={styles.tableData}/>
						<td className={styles.tableData} colSpan={3}>
							<h5>
								Maksetaan:
								<span style={{ padding: "5px" }}>
									€{_.sum(calculateAmount) / 100}
								</span>
							</h5>
						</td>
					</tr>
				</tfoot>
      </table>
    </div>
  );
};

export default ReceiptUpdateTable;
