import React from "react";

import { FaWindowClose } from "react-icons/fa";
import Button from "../Button/Button";

import styles from "./Modal.module.scss";

const Modal = ({
	modalMessage,
	handleClickButton,
	handleWindowClose,
	buttonAction,
}) => {
	return (
		<div className={styles.modalWrapper}>
			<div className={styles.confirmationBox}>
				<FaWindowClose
					onClick={handleWindowClose}
					className={styles.closeModal}
				/>
				<p>{modalMessage}</p>
				<Button
					handleClick={handleClickButton}
					buttonDescription={buttonAction}
				/>
			</div>
		</div>
	);
};

export default Modal;
