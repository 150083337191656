import React from "react";

import { GrNext, GrPrevious } from "react-icons/gr";

import styles from "./Pagination.module.scss";

const Pagination = ({
  totalPages,
  handlePageClick,
  goToNextPage,
  goToPrevPage,
  page,
}) => {
  const pageNumbers = [...Array(totalPages).keys()].map((num) => num + 1);

  return (
    <div className={styles.pagesContainer}>
      <button
        disabled={page <= 1}
        onClick={goToPrevPage}
        className={styles.prevPage}
      >
        <GrPrevious className={styles.pageArrows} />
      </button>
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => handlePageClick(pageNumber)}
          className={`${styles.pageButton} ${
            pageNumber === page && styles.activePage
          }`}
        >
          <p>{pageNumber}</p>
        </button>
      ))}
      <button
        disabled={page >= totalPages}
        onClick={goToNextPage}
        className={styles.nextPage}
      >
        <GrNext className={styles.pageArrows} />
      </button>
    </div>
  );
};

export default Pagination;
