import React, { useContext } from "react";

import ReportContext from "../store/ReportContext";
import Modal from "../common/Modal/Modal";

const DetachModal = ({
	uploadedReceipt,
	isModalDetachClicked,
	setModalDetachClicked,
}) => {
	const { handleDetach } = useContext(ReportContext);

	if (!isModalDetachClicked) return null;

	return (
		<div>
			<Modal
				modalMessage="Are you sure you want to detach this receipt?"
				handleWindowClose={() => setModalDetachClicked(false)}
				handleClickButton={() => handleDetach(uploadedReceipt.id)}
				buttonAction="Yes, Detach"
			/>
		</div>
	);
};

export default DetachModal;
