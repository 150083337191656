import React from "react";
import ExpenseReport from "./ExpenseReport/ExpenseReport";
import { BrowserRouter } from "react-router-dom";
import { ReportContextProvider } from "./store/ReportContext";

const App = ({
	//current_user,
	expense_report,
	receipts,
	unattached_receipts,
}) => {
	// console.log("expense_report", expense_report);
	// console.log("receipts", receipts);
	// console.log("unattached_receipts", unattached_receipts);

	return (
		<div>
			<BrowserRouter>
				<ReportContextProvider
					//currentUser={current_user}
					expenseReport={expense_report}
					expenseReceipts={receipts}
					unattachedReceipts={unattached_receipts}
				>
					<ExpenseReport />
				</ReportContextProvider>
			</BrowserRouter>
		</div>
	);
};

export default App;
