import React, { useState, useEffect } from "react";

import { TiDeleteOutline } from "react-icons/ti";
import { AiFillDelete } from "react-icons/ai";
import Button from "../common/Button/Button";
import DetachModal from "../DetachModal/DetachModal";
import DeleteModal from "../DeleteModal/DeleteModal";

import styles from "./UpdateForm.module.scss";

const UpdateForm = ({ handleFormUpdate, uploadedReceipt }) => {
	const [sellerField, setSellerField] = useState(uploadedReceipt.seller || "");
	const [descriptionField, setDescriptionField] = useState(
		uploadedReceipt.description || ""
	);
	const [amountField, setAmountField] = useState(
		uploadedReceipt.amount_cents / 100 || ""
	);

	const [vatField, setVatField] = useState(
		uploadedReceipt.european_union || false
	);

	const [isSuccessfullyUpdated, setSuccessfullyUpdated] = useState(false);
	const [isModalDetachClicked, setModalDetachClicked] = useState(false);

	const [isModalDeleteClicked, setModalDeleteClicked] = useState(false);

	const [ocrStatus, setIsOcrStatus] = useState(
		uploadedReceipt.ocr_job_status || ""
	);
	const [statusMessage, setStatusMessage] = useState(
		"Oh snap, autofill is unable to respond. Please fill manually"
	);

	useEffect(() => {
		setIsOcrStatus(ocrStatus);
		return () => {
			setIsOcrStatus("");
		};
	}, [ocrStatus]);

	return (
		<>
			{(() => {
				switch (ocrStatus) {
					case "null":
						return null;
					case "failure":
						return <p className={styles.failMessage}>{statusMessage}</p>;
					default:
						return null;
				}
			})()}
			<div className={styles.formContainer}>
				<div>
					<div className={styles.didFloatingLabelContent}>
						<input
							className={styles.didFloatingInput}
							name="seller"
							type="text"
							placeholder=" "
							value={sellerField}
							onChange={(event) => {
								setSellerField(event.target.value);
								setStatusMessage("");
							}}
						/>
						<label className={styles.didFloatingLabel}>Seller</label>
					</div>
					<div className={styles.didFloatingLabelContent}>
						<input
							className={styles.didFloatingInput}
							name="description"
							type="text"
							placeholder=" "
							value={descriptionField}
							onChange={(event) => {
								setDescriptionField(event.target.value);
								setStatusMessage("");
							}}
						/>
						<label className={styles.didFloatingLabel}>Description</label>
					</div>
					<div className={styles.didFloatingLabelContent}>
						<input
							className={styles.didFloatingInput}
							name="amount"
							type="number"
							placeholder=" "
							value={amountField}
							onChange={(event) => {
								setAmountField(event.target.value);
								setStatusMessage("");
							}}
						/>
						<label className={styles.didFloatingLabel}>Amount</label>
					</div>
					<label htmlFor="html" className={styles.formCheckbox}>
						<input
							className={styles.checkBox}
							type="checkbox"
							id="html"
							checked={vatField}
							name="european_union"
							onChange={(event) => setVatField(event.target.checked)}
						/>
						Includes European Union VAT
					</label>
				</div>

				<div className={styles.actionContainer}>
					{isSuccessfullyUpdated ? (
						<Button type="button" buttonDescription="Updated!" />
					) : (
						<Button
							type="submit"
							handleClick={() =>
								handleFormUpdate(uploadedReceipt.id, {
									sellerField,
									descriptionField,
									amountField,
									vatField,
									setSuccessfullyUpdated,
								})
							}
							disable={!amountField | !descriptionField | !sellerField}
							buttonDescription="Update"
						/>
					)}
					<button
						className={styles.detachIcon}
						onClick={() => setModalDetachClicked(true)}
					>
						<TiDeleteOutline />
						Detach
					</button>
					<DetachModal
						isModalDetachClicked={isModalDetachClicked}
						setModalDetachClicked={setModalDetachClicked}
						uploadedReceipt={uploadedReceipt}
					/>
				</div>
				<AiFillDelete
					className={styles.deleteButton}
					onClick={() => setModalDeleteClicked(true)}
				/>
				<DeleteModal
					isModalDeleteClicked={isModalDeleteClicked}
					setModalDeleteClicked={setModalDeleteClicked}
					uploadedReceipt={uploadedReceipt}
				/>
			</div>
		</>
	);
};

export default UpdateForm;
