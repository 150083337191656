import React, { useState, createContext } from "react";

import axios from "axios";

const ReportContext = createContext({
  fileImages: null,
  fileName: [],
  passedFiles: [],
  showSuccessIcon: false,
  isLoading: false,
  isModalDetachClicked: false,
  isDetached: [],
  isUpdated: [],
  errorMessage: "",
  addUploadedFileForm: () => {},
  handleChange: () => {},
  handleFormUpdate: () => {},
  handleDetach: () => {},
  handleAttach: () => {},
  handleDelete: () => {},
});

export const ReportContextProvider = ({
  children,
  expenseReport,
  expenseReceipts,
  unattachedReceipts,
}) => {
  const [isUpdated, setIsUpdated] = useState(expenseReceipts);
  //const [errorMessage, setErrorMessage] = useState("");

  const [fileImages, setFileImages] = useState([]);
  const [passedFiles, setPassedFiles] = useState([]);
  const [fileName, setFileName] = useState([]);

  const [showSuccessIcon, setShowSuccessIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isDetached, setIsDetached] = useState(unattachedReceipts);

  /**
   * append expense report id to file
   */
  let formData = new FormData();
  formData.append("expense_report_id", expenseReport.id);

  /**
   * multiple upload preview file onchange function
   */

  const handleChange = (event) => {
    const selectedFiles = [];
    const previewFiles = event.target.files;
    const targetFileObject = [...previewFiles];

    if (previewFiles) {
      targetFileObject.map((file) => {
        return selectedFiles.push(URL.createObjectURL(file));
      });
    }
    setFileImages(selectedFiles);
    setPassedFiles(previewFiles);
  };

  /**
   * upload multiple and single files function
   */

  const addUploadedFileForm = async () => {
    //1. collect api calls
    const promises = [];

    // 2. map over the type "File" object, append fileImages property to the single formData instance
    for (const [key, value] of Object.entries(passedFiles)) {
      formData.set("file", value);
      setIsLoading(true);

      const postData = await axios.post("/api/receipts", formData);
      //3. push to the que of api calls
      promises.push(postData);
      setIsLoading(false);
    }

    //4. pass promiess to the prototype "Promise" "I will wait all of these to resolve/fail and will gib u result :)"
    Promise.all(promises)
      .then(function handleData(data) {
        const getArrayItems = data[data.length - 1];
        const postedData = getArrayItems.data.receipts.map(({ receipt }) => {
          return receipt;
        });
        setIsUpdated(postedData);
        setShowSuccessIcon(true);
        setTimeout(() => {
          setShowSuccessIcon(false);
          setFileImages([]);
        }, 3000);
      })
      .catch(function handleError(error) {
        //console.log("Error" + error);
        //setErrorMessage("This file has already been uploaded");
        setTimeout(() => {
          setShowSuccessIcon(false);
          //setErrorMessage("");
          setFileImages([]);
          setFileName(fileName);
        }, 3000);
      });
  };

  /**
   * detach receipt function
   */

  const handleDetach = async (receiptId) => {
    try {
      const detachData = await axios.post(
        `/api/receipts/${receiptId}/detach`,
        formData
      );
      const newDetachedItem = detachData.data.user.unattached_receipts.filter(
        ({ receipt }) => {
          return receipt.id === receiptId;
        }
      );
      setIsDetached([...isDetached, newDetachedItem[0].receipt]);
      setIsUpdated(isUpdated.filter(({ id }) => id !== receiptId));
    } catch (err) {
      console.err(err);
    }
  };

  /**
   * delete receipt function
   */

  const handleDelete = async (receiptId) => {
    try {
      await axios.delete(`/api/receipts/${receiptId}`, {
        data: { expense_report_id: expenseReport.id },
      });
      setIsUpdated(isUpdated.filter(({ id }) => id !== receiptId));
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * update receipt form function
   */
  const handleFormUpdate = async (
    receiptId,
    {
      sellerField,
      descriptionField,
      amountField,
      vatField,
      setSuccessfullyUpdated,
    }
  ) => {
    try {
      const { data } = await axios.put(`/api/receipts/${receiptId}`, {
        expense_report_id: expenseReport.id,
        receipt: {
          amount: amountField,
          european_union: vatField,
          description: descriptionField,
          seller: sellerField,
        },
      });

      setIsUpdated(data.receipts.map(({ receipt }) => receipt));
      setSuccessfullyUpdated(true);
      setTimeout(() => {
        setSuccessfullyUpdated(false);
      }, 2500);
    } catch (err) {
      console.err(err);
    }
  };

  /**
   * attach unattached receipts function
   */
  const handleAttach = async () => {
    try {
      const { data } = await axios.post(`/api/receipts/attach_unattached`, {
        expense_report_id: expenseReport.id,
      });

      setIsDetached(isDetached);
      setIsUpdated(data.receipts.map(({ receipt }) => receipt));
      setIsDetached([]);
    } catch (err) {
      console.err(err);
    }
  };

  const contextDefaultValues = {
    fileImages,
    fileName,
    showSuccessIcon,
    isLoading,
    isDetached,
    isUpdated,
    // errorMessage,
    expenseReport,
    passedFiles,
    addUploadedFileForm,
    handleChange,
    handleFormUpdate,
    handleDetach,
    handleAttach,
    handleDelete,
  };
  return (
    <ReportContext.Provider value={contextDefaultValues}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportContext;
