import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { IoEyeSharp } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
// import { MdCancel } from "react-icons/md";
import { HiOutlineUpload } from "react-icons/hi";

import _ from "lodash";

import ReportContext from "../store/ReportContext";
import Spinner from "../common/Spinner/Spinner";
import Button from "../common/Button/Button";
import ReceiptUpdateTable from "../ReceiptUpdateTable/ReceiptUpdateTable";
import UpdateForm from "../UpdateForm/UpdateForm";
import Pagination from "../Pagination/Pagination";

import styles from "./ExpenseReport.module.scss";

const ExpenseReport = () => {
  const {
    fileImages,
    passedFiles,
    showSuccessIcon,
    isLoading,
    isDetached,
    isUpdated,
    expenseReport,
    // errorMessage,
    addUploadedFileForm,
    handleChange,
    handleFormUpdate,
    handleAttach,
  } = useContext(ReportContext);


  //cleanup useffect after mounting
  const componentMounted = useRef(true);

  const refresherStatus = isUpdated.map((receipt) => {
    const received = receipt.ocr_job_status;
    return received;
  });
  const getSuccessStatus = refresherStatus.includes("success");
  const getRunningStatus = refresherStatus.includes("running");

  //Pagination states
  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * 3;
  const selectedReceipts = isUpdated.slice(startIndex, startIndex + 3);
  const [totalPages, setTotalPages] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [refreshStatus, setIsRefreshStatus] = useState(refresherStatus);

  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    setShowPagination(!showPagination);
    setTotalPages(Math.ceil(isUpdated.length / 3));
    setIsRefreshStatus(refreshStatus);

    setInProgress(true);
    setTimeout(() => {
      setInProgress(false);
    }, 3000);

    if (componentMounted.current) {
      setShowPagination(false);
    }
    return () => {
      componentMounted.current = false;
    };
  }, [isUpdated]);

  // Pagination functions
  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const goToNextPage = () => {
    setPage(page + 1);
  };

  const goToPrevPage = () => {
    setPage(page - 1);
  };

  const handleRefresh = () => {
    document.location.reload(true);
  };

  //ocr status button
  let refreshOcrStatus;

  if (getSuccessStatus && refreshStatus.length > 0) {
    refreshOcrStatus = (
      <button onClick={handleRefresh} className={styles.reloadButton}>
        Autofill receipts
      </button>
    );
  } else if (getRunningStatus && !inProgress) {
    refreshOcrStatus = (
      <button onClick={handleRefresh} className={styles.reloadButton}>
        Autofill receipts
      </button>
    );
  } else if (inProgress && selectedReceipts.length > 0) {
    refreshOcrStatus = (
      <button className={styles.scanLabel}> Scanning receipts...</button>
    );
  } else {
    refreshOcrStatus = null;
  }

 /**
   *
   * preview files are objects so convert them to arrays
   */

  const convertPreviewToArray = [];
  for (const [key, value] of Object.entries(passedFiles)) {
    convertPreviewToArray.push(value.name);
  }
  const zippedFiles = fileImages.map(function (file, i) {
    return [file, convertPreviewToArray[i]];
  });

  /**
   *
   * remove preview file function
   */

  // const handleRemovePreview = (filePreview) => {
  //   //console.log("Incoming files", filePreview);
  //   const removers = zippedFiles.filter((item) => item !== filePreview);
  //   console.log("Removers", removers);
  // };

  return (
    <div>
      <h4>Unattached receipts </h4>
      {isDetached.length > 0
        ? isDetached.map((receipt) => {
            const getFileName = receipt.attachment.url
              .split("/")
              .at(-1)
              .split("?")
              .at(0);
            return <li key={receipt.id}>{getFileName}</li>;
          })
        : "There are no unattached receipts"}
      {isDetached.length > 0 ? (
        <Button
          type="submit"
          handleClick={() => handleAttach()}
          buttonDescription="Attach all unattached receipts to this expense report"
        />
      ) : null}

      <div className={styles.fileWrapper}>
        <div className={styles.uploadContainer}>
          {/* <p style={{ color: "#ff9966" }}>{errorMessage}</p> */}
          {isLoading ? (
            <Fragment>
              <div className={styles.blurContainer} />
              <Spinner className={styles.loadProgress} />
            </Fragment>
          ) : null}

          {showSuccessIcon && (
            <Fragment>
              <div className={styles.blurContainer} />
              <IoIosCheckmarkCircle className={styles.uploadSuccess} />
            </Fragment>
          )}
          <input
            type="file"
            name="file"
            onChange={handleChange}
            className={styles.fileInput}
            multiple
          />

          <div className={styles.previewContainer}>
            {zippedFiles &&
              zippedFiles.map((file) => {
                return (
                  <div key={file} className={styles.previewWrapper}>
                    {/* <MdCancel
                      className={styles.removeButton}
                      onClick={() => handleRemovePreview(file)}
                    /> */}
                    <img
                      src={file[0]}
                      alt="preview files"
                      className={styles.filePreview}
                    />
                    <p className={styles.previewName}>
                      {_.truncate(file[1], { length: 15 })}
                    </p>
                  </div>
                );
              })}
          </div>

          {fileImages.length === 0 && (
            <Fragment>
              <p className={styles.uploadIcon}>
                <HiOutlineUpload />
              </p>
              <div>
                <p>Browse or Drop files here</p>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      {/* Custom button */}
      <button
        onClick={addUploadedFileForm}
        disabled={fileImages.length === 0 || isLoading || showSuccessIcon}
        className={styles.uploadButton}
      >
        Click here to upload files
      </button>

      {selectedReceipts.length > 0 && (
        <div className={styles.uploadHeading}>
          <h4> Uploaded receipts ({isUpdated.length})</h4>
          <h5>
            Page {page} of {totalPages}
          </h5>
        </div>
      )}
      <div>{refreshOcrStatus}</div>
      <hr />
      <div className={styles.infoBox}>
        {selectedReceipts.map((uploadedReceipt) => {
          return (
            <div key={uploadedReceipt.id}>
              <div className={styles.infoContent}>
                <div className={styles.imageContainer}>
                  <button className={styles.view}>
                    <a
                      href={uploadedReceipt?.preview?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IoEyeSharp /> View receipt
                    </a>
                  </button>
                  <div className={styles.fileContainer}>
                    <img
                      alt="Uploaded receipt"
                      src={uploadedReceipt?.preview?.url || ""}
                      className={styles.receiptImage}
                    />
                  </div>
                </div>
                <UpdateForm
                  key={uploadedReceipt.id}
                  uploadedReceipt={uploadedReceipt}
                  handleFormUpdate={handleFormUpdate}
                />
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      {isUpdated.length > 0 ? (
        <Pagination
          totalPages={totalPages}
          handlePageClick={handlePageClick}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          page={page}
        />
      ) : (
        "There no uploaded receipts"
      )}
      {expenseReport.state === "draft" ? <ReceiptUpdateTable /> : null}
    </div>
  );
};

export default ExpenseReport;
