import React, { useContext } from "react";

import ReportContext from "../store/ReportContext";
import Modal from "../common/Modal/Modal";

const DeleteModal = ({
	uploadedReceipt,
	isModalDeleteClicked,
	setModalDeleteClicked,
}) => {
	const { handleDelete } = useContext(ReportContext);

	if (!isModalDeleteClicked) return null;

	return (
		<div>
			<Modal
				modalMessage="Are you sure you want to delete this receipt?"
				handleWindowClose={() => setModalDeleteClicked(false)}
				handleClickButton={() => handleDelete(uploadedReceipt.id)}
				buttonAction="Delete"
			/>
		</div>
	);
};

export default DeleteModal;
