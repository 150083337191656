import React from "react";

import styles from "./Button.module.scss"

const Button = ({ type, handleClick, disable, buttonDescription }) => {
	return (
		<button
			type={type}
			className={styles.buttonStyles}
			onClick={handleClick}
			disabled={disable}
		>
			{buttonDescription}
		</button>
	);
};

export default Button;
